<template>
  <div class="lists-manager">
    <modal
      :active="createNewListActive"
      @dismiss="createNewListActive = false"
      class="create-list-modal form-control"
    >
      <template v-if="activeList">
        <h2 v-if="activeList.id">Update List</h2>
        <h2 v-else>Create New List</h2>

        <div class="form-row">
          <label>Name</label>
          <input v-model="activeList.name" type="text" />
        </div>

        <div class="form-row">
          <label>List Type</label>
          <select v-model="activeList.dynamic">
            <option value="0">Manual</option>
            <option value="1">Dynamic</option>
          </select>
          <p v-if="parseInt(activeList.dynamic) === 0">
            Add and remove leads directly to the list
          </p>
          <p v-else>
            Leads are added and removed dynamically based on filter criteria
          </p>
        </div>

        <div v-if="parseInt(activeList.dynamic) === 1" class="form-row">
          <div class="leads-criteria">
            <div class="leads-criteria__header">
              <label>List Criteria</label>
              <button class="button" @click="addCriteriaRow">+</button>
            </div>
            <div
              v-for="(row, index) in activeList.criteria"
              :key="`row-${index}`"
              class="leads-criteria__item"
            >
              <select v-model="activeList.criteria[index]['field']">
                <option value="">Field</option>
                <option value="leads.first_name">First Name</option>
                <option value="leads.last_name">Last Name</option>
                <option value="leads.email">Email</option>
                <option value="leads.zip_code">Zip</option>
                <option value="leads.campaign_id">Campaign ID</option>
                <option value="leads.provider_id">Provider ID</option>
                <option value="leads.location_id">Location ID</option>
                <option value="leads.form_count">Form Count</option>
                <option value="leads.qualification_stage">
                  Qualification Stage
                </option>
                <option value="conversions.type">Conversion Type</option>
                <option value="conversion_actions.event_id">
                  Disposition Event ID
                </option>
                <option value="conversion_actions.data">
                  Disposition Data
                </option>
              </select>
              <select v-model="activeList.criteria[index]['operator']">
                <option value="=">=</option>
                <option value="!=">!=</option>
                <option value=">">&gt;</option>
                <option value="<">&lt;</option>
                <option value="like">like</option>
                <option value="in">in</option>
              </select>
              <input
                v-model="activeList.criteria[index]['value']"
                type="text"
                placeholder="value"
              />
              <button class="button delete" @click="removeCriteriaRow(index)">
                x
              </button>
            </div>
          </div>
        </div>

        <button class="button" @click="onCreate">Submit</button>
      </template>
    </modal>

    <modal
      :active="viewListActive"
      @dismiss="viewListActive = false"
      class="view-list-modal form-control"
    >
      <template v-if="activeList">
        <div class="view-list-modal__meta">
          <h2>{{ activeList.name }}</h2>
          <button class="button" @click="onDownloadLeads">
            Download Leads
          </button>
        </div>
        <table class="styled-table">
          <tr>
            <th>Name</th>
            <th>Zip</th>
            <th>Campaign</th>
            <th>Conversion</th>
          </tr>
          <tr v-for="lead in leads" :key="`lead-${lead.id}`">
            <td>{{ lead.first_name }} {{ lead.last_name }}</td>
            <td>
              {{ lead.zip_code }}
            </td>
            <td>
              <template v-if="lead.campaign">
                {{ lead.campaign.name }}
              </template>
            </td>
            <td>
              <strong
                v-if="lead.conversion"
                @click="onSelectConversion(lead.conversion.id)"
                >View Conversion</strong
              >
            </td>
          </tr>
        </table>
      </template>
    </modal>

    <modal
      :active="viewMessagesActive"
      @dismiss="viewMessagesActive = false"
      class="view-messages-modal form-control"
    >
      <template v-if="activeList">
        <div class="view-list-modal__meta">
          <h2>{{ activeList.name }} Messages</h2>
          <button class="button" @click="onNewMessage">Create Message</button>
        </div>
        <table class="styled-table">
          <tr>
            <th>Date</th>
            <th>Status</th>
            <th>View</th>
            <th>Type</th>
            <th>Stats</th>
            <th>Delete</th>
          </tr>
          <tr
            v-for="message in activeList.messages"
            :key="`message-${message.id}`"
          >
            <td>{{ message.available_date }}</td>
            <td>
              {{ message.status }}
              <span v-if="message.status === 'complete'">
                {{ message.completed_date }}
              </span>
            </td>
            <td>{{ message.view.name }}</td>
            <td>{{ message.view.type }}</td>
            <td>
              <Pill
                v-if="message.pending > 0"
                :count="`${message.pending} pending`"
                :alert="true"
              />
              &nbsp;
              <Pill
                v-if="message.sent > 0"
                :count="`${message.sent} sent`"
                :label="true"
              />
            </td>
            <td>
              <ActionButton
                v-if="message.status === 'pending'"
                @click="onDeleteMessage(message)"
                :negative="true"
                class="control"
              />
            </td>
          </tr>
        </table>
      </template>
    </modal>

    <modal
      :active="createMessageActive"
      @dismiss="createMessageActive = false"
      class="create-list-modal form-control"
    >
      <template v-if="activeList && activeMessage">
        <h2>New Message List for {{ activeList.name }}</h2>
        <p>Messaging up to {{ activeList.leads_count }} leads.</p>

        <div class="form-row">
          <label>Available Date</label>
          <input v-model="activeMessage.available_date" type="datetime-local" />
        </div>

        <div class="form-row">
          <label>Message Type</label>
          <select v-model="activeMessage.type">
            <option value="email">Email</option>
            <option value="sms">SMS</option>
          </select>

          <p v-if="activeMessage.type">
            Only leads with a valid {{ messageField }} will receive this
            message.
          </p>
        </div>

        <div v-if="activeMessage.type" class="form-row">
          <label>Message</label>
          <select v-model="activeMessage.view_id">
            <option
              v-for="(view, index) in views"
              :key="`view-${index}`"
              :value="view.id"
            >
              {{ view.campaign.name }} | {{ view.name }}
            </option>
          </select>
        </div>

        <button class="button" @click="onCreateMessageList">Submit</button>
      </template>
    </modal>

    <loading :active="loading" />

    <div class="hero hero--activate hero--lists">
      <div class="container">
        <h2>Lists</h2>

        <button class="button" @click="onNewList">Add New List</button>
      </div>
    </div>

    <div class="container">
      <div class="lists-wrapper">
        <div class="card form-control">
          <template v-if="lists.length">
            <table class="styled-table">
              <tr>
                <th>Leads</th>
                <th>Name</th>
                <th>Type</th>
                <th>&nbsp;</th>
              </tr>
              <tr v-for="list in lists" :key="`list-${list.id}`">
                <td>
                  <Pill
                    :count="list.leads_count"
                    :label="list.leads_count > 0"
                    :alert="list.leads_count === 0"
                  />
                </td>
                <td @click="onSelectList(list)" class="styled-table__pointer">
                  <strong>{{ list.name }}</strong>
                </td>
                <td>{{ list.dynamic | typeFilter }}</td>
                <td>
                  <MenuButton :position="'right'" :width="'small'">
                    <ul>
                      <li
                        v-for="(action, index) in getActions(list)"
                        :key="`action-${index}`"
                        @click="action.action(action.actionParams || null)"
                      >
                        {{ action.label }}
                      </li>
                    </ul>
                  </MenuButton>
                </td>
              </tr>
            </table>
          </template>
          <template v-else>
            <p class="alert">No Lists</p>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Modal from "@/components/Modal";
import Loading from "@/components/Loading";
import List from "@/models/List";
import ListMessage from "@/models/ListMessage";
import Pill from "@/components/Pill";
import ActionButton from "@/components/ActionButton";
import Papa from "papaparse";
import FileDownload from "js-file-download";
import MenuButton from "@/components/MenuButton";

export default {
  name: "Lists",
  components: {
    Modal,
    Loading,
    Pill,
    ActionButton,
    MenuButton,
  },
  data() {
    return {
      createNewListActive: false,
      editListActive: false,
      pageLength: 24,
      viewListActive: false,
      viewMessagesActive: false,
      createMessageActive: false,
      activeList: null,
      activeMessage: null,
      type: "",
    };
  },
  filters: {
    typeFilter(value) {
      if (value === 1) {
        return "Dynamic";
      }

      return "Manual";
    },
  },
  computed: {
    loading() {
      return this.$store.getters["lists/loading"];
    },
    leads() {
      return this.$store.getters["lists/leads"];
    },
    lists() {
      return this.$store.getters["lists/lists"];
    },
    views() {
      if (!this.activeMessage) {
        return [];
      }
      let message_type = this.activeMessage.type;
      return this.$store.getters["contentOffers/views"].filter(function (view) {
        return view.type === message_type;
      });
    },
    messageField() {
      if (this.activeMessage.type === "email") {
        return "email address";
      }

      return "phone number";
    },
  },
  methods: {
    addCriteriaRow() {
      this.activeList.criteria.push({
        field: "",
        operator: "=",
        value: "",
      });
    },
    removeCriteriaRow(index) {
      this.activeList.criteria.splice(index, 1);
    },
    onSelectList(list) {
      this.activeList = list;
      this.createNewListActive = true;
    },
    onNewList() {
      this.activeList = new List();
      this.createNewListActive = true;
    },
    onViewList(item) {
      this.activeList = item;
      this.viewListActive = true;
    },
    onCreate() {
      if (parseInt(this.activeList.dynamic) === 0) {
        this.activeList.criteria = [];
      }
      if (this.activeList.id) {
        this.onUpdate();
        return;
      }

      this.$store.dispatch("lists/addList", this.activeList).then(() => {
        this.activeList = null;
        this.createNewListActive = false;

        this.$store.dispatch("lists/getLists");
      });
    },
    onCreateMessageList() {
      this.activeMessage.list_id = this.activeList.id;

      this.$store
        .dispatch("lists/addMessageList", this.activeMessage)
        .then(() => {
          this.activeList = null;
          this.activeMessage = null;
          this.createMessageActive = false;

          this.$store.dispatch("lists/getLists");
        });
    },
    onDeleteMessage(message) {
      this.$store.dispatch("lists/deleteMessageList", message).then(() => {
        this.activeList = null;
        this.activeMessage = null;
        this.viewMessagesActive = false;

        this.$store.dispatch("lists/getLists");
      });
    },
    onActivateEdit() {
      this.viewListActive = false;
      this.editListActive = true;
    },
    onUpdate() {
      this.$store.dispatch("lists/updateList", this.activeList).then(() => {
        this.activeList = null;
        this.createNewListActive = false;

        this.$store.dispatch("lists/getLists");
      });
    },
    onRefresh(list) {
      this.$store.dispatch("lists/refreshList", list).then(() => {
        this.$store.dispatch("lists/getLists");
      });
    },
    onGetLeads(list) {
      this.$store.dispatch("lists/getLeads", list).then(() => {
        this.activeList = list;
        this.viewListActive = true;
      });
    },
    onSelectConversion(conversionId) {
      let routeData = this.$router.resolve({
        name: "conversion",
        params: { id: conversionId },
      });
      window.open(routeData.href, "_blank");
    },
    onDownloadLeads() {
      const csv = Papa.unparse(this.formatDownloadList());
      FileDownload(csv, `${this.activeList.name}.csv`);
    },
    formatDownloadList() {
      return this.leads.map(function (lead) {
        return {
          first_name: lead.first_name,
          last_name: lead.last_name,
          zip: lead.zip_code,
          campaign: lead.campaign.name,
          conversion_id: lead.conversion?.id,
        };
      });
    },
    onLeadMessages(list) {
      this.activeList = list;
      this.viewMessagesActive = true;
    },
    onNewMessage() {
      this.activeMessage = new ListMessage();
      this.viewMessagesActive = false;
      this.createMessageActive = true;
    },
    getActions(list) {
      const actions = [];

      if (list.dynamic) {
        actions.push({
          action: this.onRefresh,
          actionParams: list,
          label: "Refresh List",
        });
      }

      if (list.leads_count) {
        actions.push({
          action: this.onGetLeads,
          actionParams: list,
          label: "View Leads",
        });
        actions.push({
          action: this.onLeadMessages,
          actionParams: list,
          label: "Manage Messages",
        });
      }

      return actions;
    },
  },
  mounted() {
    if (!this.views.length) {
      this.$store.dispatch("contentOffers/getViews");
    }

    if (!this.lists.length) {
      this.$store.dispatch("lists/getLists");
    }
  },
};
</script>

<style lang="scss">
@import "@/scss/colors";
@import "@/scss/button";
@import "@/scss/forms";
@import "@/scss/table";
@import "@/scss/hero";

.hero--lists {
  .container {
    justify-content: space-between;

    .button {
      margin-left: 0 !important;
    }
  }
}

.lists-manager {
  .form-row {
    flex-grow: 1;
    margin-bottom: 1em;

    input[type="file"] {
      margin-bottom: 1.25rem;
    }

    label {
      display: block;
      margin-bottom: 6px;
    }

    textarea {
      height: 75px;
    }
  }

  .lists-wrapper {
    th {
      &:first-of-type {
        padding-right: 0;
      }
    }

    td {
      vertical-align: middle;

      &:first-of-type {
        width: 64px;
        padding-right: 0;
      }

      &:last-of-type {
        text-align: right;

        .menu-button {
          &__button {
            svg {
              fill: $medium-gray;
              height: 16px;
            }
          }

          &__menu {
            padding-top: 12px;
            padding-bottom: 12px;

            ul {
              width: calc(100% + 3rem);
              margin-left: -1.5rem;
              margin-right: -1.5rem;

              li {
                border-bottom: solid 1px $fade-gray;
                cursor: pointer;
                padding: 1rem 1.5rem;
                font-weight: 600;

                &:hover {
                  background-color: $fade-gray;
                }

                &:last-child {
                  border: 0;
                }
              }
            }
          }
        }
      }
    }

    .lists-item {
      padding-bottom: calc(16.666% * 0.75);
      width: 16.666%;
    }
  }
}

.view-list-modal,
.view-messages-modal {
  .modal-content {
    width: 960px;
  }

  &__meta {
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin-bottom: 2rem;

    h2 {
      margin-bottom: 0 !important;
    }
  }

  strong {
    color: $light-blue;
    cursor: pointer;
  }
}

.create-list-modal {
  p {
    font-size: 12px;
    font-style: italic;
    margin: -1rem 0 2.25rem;
  }
}

.lists-manager {
  .styled-table {
    strong {
      display: block;
      font-size: 15px;
      font-weight: 600;
      color: $gray;
    }
  }
}

.leads-criteria {
  &__header {
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin-bottom: 1rem;
  }

  &__item {
    display: flex;
    justify-content: space-between;

    input,
    select {
      width: 35% !important;
    }

    select:nth-child(2) {
      width: 25% !important;
    }
  }

  .delete {
    align-self: flex-start;
    background-color: $red;
    color: #fff;
    padding: 0.25rem 0.5rem;
  }
}
</style>