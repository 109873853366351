function ListMessage() {
  return {
    list_id: null,
    available_date: null,
    type: "email",
    view_id: null,
  };
}

export default ListMessage;
